$(document).ready(function () {
  // qr capture
  let scanner = undefined, $modal_qr = $('#check_qr'), $qr_container = $modal_qr.find('.modal__qr');
  if ($modal_qr.length < 1) return;

  // jsQR
  (function () {
    $('#preview').replaceWith('<canvas id="preview"></canvas>');
    let video = document.createElement("video"),
      canvasElement = document.getElementById("preview"),
      canvas = canvasElement.getContext("2d");

    function drawLine(begin, end, color) {
      canvas.beginPath();
      canvas.moveTo(begin.x, begin.y);
      canvas.lineTo(end.x, end.y);
      canvas.lineWidth = 4;
      canvas.strokeStyle = color;
      canvas.stroke();
    }

    let started = false, interval = setInterval(function () {
      if ($modal_qr.is(':visible') && !started) {
        started = true;
        startVideo();
      }

      if (!$modal_qr.is(':visible') && started) {
        started = false;
      }
    }, 500);

    function startVideo() {
      if (video.srcObject === null) {
        navigator.mediaDevices.getUserMedia({video: {facingMode: "environment"}})
          .then(function (stream) {
            video.srcObject = stream;
            video.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
            video.play();
          });
      }
    }

    function stopVideo() {
      video.srcObject.getTracks().forEach(function (track) {
        track.stop();
      });
      video.srcObject = null;
    }
  
    requestAnimationFrame(function checkVideoIsVisible() {
      if ($modal_qr.is(':visible')) {
        if (video.readyState === video.HAVE_ENOUGH_DATA) {
          canvasElement.height = video.videoHeight;
          canvasElement.width = video.videoWidth;
          canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
          let imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height),
            code = jsQR(imageData.data, imageData.width, imageData.height, {
              inversionAttempts: "dontInvert",
            });

          if (code && code.data && code.data.length > 10) {
            drawLine(code.location.topLeftCorner, code.location.topRightCorner, "#FF3B58");
            drawLine(code.location.topRightCorner, code.location.bottomRightCorner, "#FF3B58");
            drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, "#FF3B58");
            drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, "#FF3B58");

            stopVideo();

            // console.log('qr', code.data);
            $('#check_qr_qr').val(code.data);
            $('#check_qr_qr').closest('form').trigger('submit');

            $qr_container.addClass('modal__qr--scanned');
            $qr_container.find('.qr__container').empty().qrcode(code.data);
          }
        }
      } else {
        $qr_container.removeClass('modal__qr--scanned');
        $('#check_qr_qr').val('');
        // console.log('qr', '=====');
        if (video.srcObject !== null) {
          stopVideo();
        }
      }
      requestAnimationFrame(checkVideoIsVisible);
    });
  })();
});
