// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// import Rails from "@rails/ujs"

// Rails.start()

window.jQuery = require("jquery");
require("jquery-ui-dist/jquery-ui");
require("jquery-ujs");
window.$ = window.jQuery361 = jQuery; // fuck! где-то перебивается jQuery =(

import * as Popper from "@popperjs/core";

window.Popper = Popper;
window.bootstrap = require("bootstrap");

require("scripts/libs/core_functions");
require("scripts/libs/slick.min");
require("scripts/libs/jquery.inputmask.min");
require("scripts/libs/jquery.qrcode.min");
require("scripts/libs/inputmask.binding");
require("scripts/libs/multipart-form");
require("scripts/libs/social-share-button");
require("scripts/libs/loader");
require("scripts/libs/js_qr");
import * as Shake from "scripts/libs/shake";
require("scripts/main");
require("scripts/game");
require("scripts/quiz");

import jsQR from "jsqr";
import raf from "raf";

window.jsQR = jsQR;
window.Shake = Shake;
window.raf = raf;

let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
let popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
  return new bootstrap.Popover(popoverTriggerEl)
})

let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new bootstrap.Tooltip(tooltipTriggerEl)
})
