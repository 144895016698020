import * as Vue from 'vue';

$(document).ready(($) => {
  let quizId = 'quiz__app',
    main = document.getElementById(quizId),
    quizApp = Vue.createApp({
      data() {
        return {
          step: 1,
          question: 1,
          questions: [],
          selected: {q1: false, q2: false, q3: false, q4: false, q5: false, q6: false, q7: false, q8: false},
          result: {quiz_result: false},
        }
      },
      computed: {},
      mounted: function () {
        this.setQuestions(window.quiz_questions_to_vue);
      },
      methods: {
        setQuestions(questions) {
          this.questions = questions;
        },
        goToTest() {
          this.step = 2;
        },
        passQuestion() {
          if (this.question >= 8) {
            $('#js--form__quiz').trigger('submit');
          } else {
            this.question++;
          }
        },
        showResult(result) {
          this.result = result;
          this.step = 3;
        },
        reset() {
          this.step = 1;
          this.question = 1;
          this.selected = {q1: false, q2: false, q3: false, q4: false, q5: false, q6: false, q7: false, q8: false};
          this.result = {quiz_result: false};
        },
      }
    });
  
  // Монтируем JS-приложение к DOM-элементу
  if (!!main) {
    $(main).removeClass('d-none').find('.quiz__block-inner.d-none').removeClass('d-none');
    
    quizApp.__mounted__ = quizApp.mount('#' + quizId);
    window.quizApp = quizApp;
  }
});
