'use strict';
$(document).ready(($) => {

  $('.faq__item-q').on('click', function () {
    $(this).parent('.faq__item').toggleClass('faq__item--active');
    return false;

  });

  const swiper = new Swiper('.swiper', {
    // Optional parameters
    loop: true,
    slidesPerView: 2,
    slidesPerGroup: 1,

    breakpoints: {
      // when window width is >= 320px
      700: {
        slidesPerView: 3,
        slidesPerGroup: 1,
      },
    },
    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
    },

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });


  $('.js-slick').slick({
    slidesToShow: 4,
    slidesToScroll: 4
  })
  $('.js--burger').on('click', function () {
    $('body').toggleClass('menu__show');
    return false;
  });
  $('.header__menu a').on('click', function () {
    $('body').removeClass('menu__show');
  });

  $('body')
    .on('change', '#reg_user_phone', function (e) {
      $('#check_code_user_phone').val($(this).val());
    })
    .on('click', '.js--check_phone_btn', function (e) {
      e.preventDefault();
      e.stopPropagation();
      $('#js--form__phone_confirm').trigger('submit');

      $('.js--check_phone_btn').closest('.modal-form-row').slideUp();

      setTimeout(function () {
        $('.js--phone_code').hide().removeClass('d-none').slideDown('slow');
      }, 500);

      setTimeout(function () {
        $('.js--phone_recall').hide().removeClass('d-none').slideDown('slow');
      }, 60 * 1000);
    })
    .on('click', '.js--phone_recall a', function (e) {
      e.preventDefault();
      $('#js--form__phone_confirm').trigger('submit');
      $('.js--phone_recall').hide();

      setTimeout(function () {
        $('.js--phone_recall').hide().removeClass('d-none').slideDown('slow');
      }, 60 * 1000);
      return false;
    });


  // message
  window.showBootstrapMessage = function (params) {
    params = $.extend({
      title: '',
      message: ''
    }, params);

    let $v_modal = $('.modal:visible'),
      $m_modal = $('#modal-message'), $title = $m_modal.find('.modal-title'), $message = $m_modal.find('.modal-body');

    if ($m_modal.length < 1) {
      alert(params.title + '\n\n' + params.message);
      return;
    }

    $v_modal.modal('hide');
    $('.modal-backdrop').remove();

    $title.html(params.title);
    $message.html('<p>' + params.message + '</p>');

    $m_modal.modal('show');

    if (typeof params['afterHide'] === 'function') {
      $m_modal.on('hidden.bs.modal', function () {
        $m_modal.off('hidden.bs.modal');
        params.afterHide.call();
      });
    }
  };

  // modal
  window.showBootstrapModal = function (selector, params) {
    params = $.extend({
      afterHide: null
    }, params);

    let $v_modal = $('.modal:visible'), $m_modal = $(selector);

    $v_modal.modal('hide');
    $('.modal-backdrop').remove();

    $m_modal.modal('show');

    if (typeof params['afterHide'] === 'function') {
      $m_modal.on('hidden.bs.modal', function () {
        $m_modal.off('hidden.bs.modal');
        params.afterHide.call();
      });
    }
  };
  
  if ($('body').hasClass('body--end') && $('body').hasClass('welcome__index')) {
    showBootstrapModal('#modal-ended');
  }
  
  // dadata
  (function () {
    if ($('#address').length < 1) {
      return false;
    }

    function loadScript(item) {
      return new Promise(function (resolve, reject) {
        let script = document.createElement('script');
        script.src = item.url;
        script.async = false;
        Object.keys(item.params).forEach(key => {
          script[key] = item.params[key];
        });
        script.onload = function () {resolve(item.url);};
        script.onerror = function () {reject(item.url);};
        document.body.appendChild(script);
      });
    }

    function appendJSLibs(callback) {
      let scripts = [{
        url: 'https://cdn.jsdelivr.net/npm/suggestions-jquery@latest/dist/js/jquery.suggestions.min.js', params: {}
      }];

      // save all Promises as array
      let promises = [];
      scripts.forEach(function (item) {promises.push(loadScript(item))});

      Promise.all(promises)
        .then(function () {
          // console.log('all scripts loaded');
          if (typeof callback === 'function') {
            callback.call(this);
          }
        }).catch(function (script) {
        console.log(script + ' failed to load');
      });
    }

    function showSelected(suggestion) {
      let address = suggestion.data;

      // console.log(address);

      $('#address_post_index').val(address.postal_code ? address.postal_code : '');
      $('#address_region').val(address.region_with_type ? address.region_with_type : '');

      let a = [];
      if (address.area_with_type) {
        a.push(address.area_with_type);
      }
      if (address.sub_area_with_type) {
        a.push(address.sub_area_with_type);
      }
      $('#address_area').val(a.length ? a.join(' ') : '');

      let c = [];
      if (address.city_with_type) {
        c.push(address.city_with_type);
      }
      if (address.settlement_with_type) {
        c.push(address.settlement_with_type);
      }
      $('#address_city').val(c.length ? c.join(' ') : '');

      $('#address_street').val(address.street_with_type ? address.street_with_type : '');
      $('#address_building').val(address.block ? `${address.block_type} ${address.block}` : '');
      $('#address_housing').val(address.stead ? `${address.stead_type} ${address.stead}` : '');
      $('#address_house').val(address.house ? `${address.house_type} ${address.house}` : '');
      $('#address_flat').val(address.flat ? `${address.flat_type} ${address.flat}` : '');

      $("#address_full").val(JSON.stringify(address));
    }

    appendJSLibs(function () {
      $("#address_address").suggestions({
        // Замените на свой API-ключ
        token: "80cd9e951cab7e40b38ca10fdfe08a53508cdb54",
        type: "ADDRESS",
        params: {
          division: "municipal"
        },
        // Вызывается, когда пользователь выбирает одну из подсказок
        onSearchStart: function (params) {
          loader.enabled = false;
        },
        onSearchComplete: function (query, suggestions) {
          loader.enabled = false;
        },
        onSearchError: function (query, jqXHR, textStatus, errorThrown) {
          loader.enabled = false;
        },
        onSuggestionsFetch: function (suggestions) {
          loader.enabled = false;
        },
        onSelect: showSelected
      });
    });

  })();

  // winners
  (function () {
    $('body')
      .on('change blur', '.js--winners-form #winner_email', function (e) {
        if (this.value.length < 1 || this.value.match(/@[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/) !== null) {
          $('#winner_page').val('1');
          $(this.form).trigger('submit');
        }
      })
      .on('change', '.js--winners-form #winner_week', function (e) {
        $(this.form).trigger('submit');
      })
      .on('click', '.pager a', function (e) {
        e.preventDefault();
        $('#winner_page').val($(this).data('page'));
        $('#winner_page').closest('form').trigger('submit');
      });
  })();
});
