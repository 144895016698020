// Инициализация
(function ($) {
  if (typeof window['loader'] === "undefined") {
    window.loader = {
      enabled: true,
      reenable: true,
      inited: false,
      selector: '.loader',
      show: function () {
        if (!$(this.selector).length) {
          $("body").append('<div class="' + this.selector.replace(/\./, '') + '"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>');
        }
        $(this.selector).show();
      },
      hide: function () {
        $(this.selector).hide();
        
        if (typeof grecaptcha_reset !== 'undefined') {
          grecaptcha_reset();
        }
      }
    };
  }
  if (window.loader.inited) {
    return false; // на случай turbolink исключим повторное срабатывание
  }
  
  $(document).ready(function () {
    $(document).ajaxStart(function () {
      if (window.loader.enabled) {
        window.loader.show();
      }
    }).ajaxStop(function () {
      window.loader.hide();
      if (window.loader.reenable) window.loader.enabled = true;
    }).ajaxError(function (event, jqxhr, settings, thrownError) {
      if (jqxhr.status === 413) {
        showBootstrapMessage({
          title: 'Ошибка загрузки файла',
          message: 'Допускается загружать файлы размером до 10Мб.'
        });
      }
    });
    // Показать / Скрыть лоадер
    window.loader.inited = true; // на случай turbolink исключим повторное срабатывание
  });
})(jQuery);
