import * as Vue from 'vue/dist/vue.esm-bundler';

$(document).ready(($) => {
  // наклоны
  let orientation = {
    // активация
    activate: function () {
      let self = this;
      if (typeof DeviceOrientationEvent.requestPermission === 'function') {
        DeviceMotionEvent.requestPermission()
          .then(permissionState => {
            if (permissionState === 'granted') {
              window.addEventListener('deviceorientation', self.callback);
            }
          }).catch(console.error);
      }
    },
    // коллбэк
    callback: function (event) {
      let x = event.beta,  // In degree in the range [-180,180], x, 'front to back'
        y = event.gamma, // In degree in the range [-90,90], y, 'left to right'
        z = event.alpha, // 0-360, z, compass orientation

        // coord 1: 0,0
        // coord 2: x,y
        // calculate the angle
        rad = Math.atan2(y, x),
        deg = rad * (180 / Math.PI),

        // take into account if phone is held sideways / in landscape mode
        screenOrientation = screen.orientation || screen.mozOrientation || screen.msOrientation,
        // 90, -90, or 0
        angle = screenOrientation.angle || window.orientation || 0;

      deg = deg + angle;

      // @todo: selector
      for (let bottles = document.getElementsByClassName('bottle'), len = bottles.length, i = 0; i < len; i++) {
        let bottle = bottles[i];
        bottle.style.transform = bottle.style.transform.replace(/rotate\([^\)]+\)/, '') + ' rotate(' + -deg + 'deg)';
      }
    }
  };

  // тряска
  let shake = {
    // состояние и настройки чувствительности
    stored: {
      options: {
        threshold: 10,
        timeout: 1000
      },
      lastTime: (new Date()),
      event: 'shake',
      lastX: null,
      lastY: null,
      lastZ: null
    },

    // запрос разрешения
    activate: function () {
      let self = this;
      if (typeof DeviceMotionEvent.requestPermission === 'function') {
        DeviceMotionEvent.requestPermission()
          .then(permissionState => {
            if (permissionState === 'granted') {
              window.gameApp.__mounted__.setIsGranted();
              window.addEventListener('devicemotion', self.callback);
            }
          })
          .catch(console.error);
      } else {
        // handle regular non iOS 13+ devices
        window.gameApp.__mounted__.setIsGranted();
        window.addEventListener('devicemotion', self.callback);
      }
    },

    // коллбэк
    callback: function (event) {
      if (window.gameApp.__mounted__.getStep() !== 1) {
        return;
      }

      let self = shake,
        current = event.accelerationIncludingGravity,
        currentTime,
        timeDifference,
        deltaX = 0,
        deltaY = 0,
        deltaZ = 0;

      if ((self.stored.lastX === null) && (self.stored.lastY === null) && (self.stored.lastZ === null)) {
        self.stored.lastX = current.x;
        self.stored.lastY = current.y;
        self.stored.lastZ = current.z;
        return;
      }


      deltaX = Math.abs(self.stored.lastX - current.x);
      deltaY = Math.abs(self.stored.lastY - current.y);
      deltaZ = Math.abs(self.stored.lastZ - current.z);

      // console.log(event.rotationRate);

      if (
        ((deltaX > self.stored.options.threshold) && (deltaY > self.stored.options.threshold))
        || ((deltaX > self.stored.options.threshold) && (deltaZ > self.stored.options.threshold))
        || ((deltaY > self.stored.options.threshold) && (deltaZ > self.stored.options.threshold))
      ) {
        //calculate time in milliseconds since last shake registered
        currentTime = new Date();
        timeDifference = currentTime.getTime() - self.stored.lastTime.getTime();

        if (timeDifference > self.stored.options.timeout) {
          // увеличиваем доступный лимит
          window.gameApp.__mounted__.increaseHealthPower();

          // window.dispatchEvent(stored.event);
          self.stored.lastTime = new Date();

          // shake for N times
          (function () {
            let times = 60;
            raf(function iterate() {
              if (--times > 0) {
                window.gameApp.__mounted__.doShake();
                raf(iterate);
              }
            })
          })();
        }
      }

      self.stored.lastX = current.x;
      self.stored.lastY = current.y;
      self.stored.lastZ = current.z;
    }
  };
  
  // Далее — Vue
  let gameId = 'game__app',
    main = document.getElementById(gameId),
    gameApp = Vue.createApp({
      data() {
        return {
          timer: {
            timeout: 30,
            text: "00:00",
            startedAt: null,
            healthPowerIncr: 100,
            healthPowerLimit: 500,
            healthPowerPercent: 0,
          },
          isRunning: false,
          isMobile: false, // мобила?
          isGranted: false, // разрешено?
          step: 0, // шаг (0 - заставка; 1 - игра; 2 - результат)
        }
      },
      computed: {},
      mounted: function () {
        // show on mounted
        if (!!main) {
          main.className = main.className.replace('d-none', '');
        }
        this.isMobile = this.checkIsMobile();
      },
      methods: {
        checkIsMobile: function () {
          let _isMobile = false; //initiate as false
          // device detection
          if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
            _isMobile = true;
          }
          return _isMobile;
        },
        shakeActivate() {
          // активация событий
          orientation.activate();// наклоны
          shake.activate(); // тряска
        },
        setIsGranted() {
          this.isGranted = true;
        },
        getStep() {
          return this.step;
        },
        startGame() {
          // начало игры
          this.step = 1;
          this.startTicker();
        },
        startTicker() {
          let self = this;

          if (!self.isRunning) {
            let stopAt = new Date;
            stopAt.setTime(stopAt.getTime() + self.timer.timeout * 1000);
            self.timer.stopAt = stopAt;
            self.timer.healthPower = self.timer.healthPowerLimit;
            self.isRunning = true;
          }

          raf(function tick() {
            let remaining = parseInt((self.timer.stopAt.getTime() - (new Date).getTime()) / 1000, 10);

            let m = parseInt(remaining / 60, 10),
              s = remaining - m * 60,
              mm = '' + (m > 9 ? m : '0' + m),
              ss = '' + (s > 9 ? s : '0' + s);

            self.timer.text = mm + ':' + ss;

            // HP
            self.timer.healthPower = self.timer.healthPower - Math.random();
            if (self.timer.healthPower < 0) {
              self.timer.healthPower = 0;
            }

            self.timer.healthPowerPercent = parseInt(100 * self.timer.healthPower / self.timer.healthPowerLimit, 10);

            if (remaining < 1 && self.timer.healthPower > 0) {
              self.win();
            } else if (remaining < 1 || self.timer.healthPower < 1) {
              self.loose();
            } else {
              raf(tick);
            }
          });
        },
        increaseHealthPower() {
          let self = this;
          self.timer.healthPower = self.timer.healthPower + self.timer.healthPowerIncr;
          if (self.timer.healthPower > self.timer.healthPowerLimit) {
            self.timer.healthPower = self.timer.healthPowerLimit;
          }
          self.timer.healthPowerPercent = parseInt(100 * self.timer.healthPower / self.timer.healthPowerLimit, 10);
        },
        doShake() {
          let
            step = 40,
            r1 = Math.ceil(step * Math.random()) - step / 2,
            r2 = Math.ceil(step * Math.random()) - step / 2,
            anims = $('.bottle__translate');

          for (let i = 0, len = anims.length; i < len; i++) {
            let anim = anims[i];
            anim.style.transform = anim.style.transform.replace(/translate\([^\)]+\)/, '') + ' translate(' + r1 + 'px,' + r2 + 'px)';
            // anim.style.border = '1px solid red';
          }
        },
        win() {
          let self = this;
          self.isRunning = false;
          self.step = 3;
          $('#game_is_passed').val(1);
          $('#js--form__game').trigger('submit');
        },
        loose() {
          let self = this;
          self.isRunning = false;
          self.step = 2;
          $('#game_is_passed').val(0);
          $('#js--form__game').trigger('submit');
        },
        reset() {
          this.step = 1;
          this.startTicker();
        }
      }
    });

  // Монтируем JS-приложение к DOM-элементу
  if (!!main) {
    gameApp.__mounted__ = gameApp.mount('#' + gameId + '--wrapper');
    window.gameApp = gameApp;
  }
});
